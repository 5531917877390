<template>
  <body>
    <div class="banner-bg">
      <div class="banner-content">
        <v-container>
          <v-divider></v-divider>
            <v-col cols="12" sm="12" md="6">
              <v-row class="banner-h" align="end">
                <v-col cols="1" class="line-banner pa-1 d-none d-sm-flex">
                </v-col>
                <v-col cols="12" sm="11" class="pa-0">
                  <span class="white-text heading mgt-12px">
                    DATA SCIENCE 2019<br/>
                    PATHWAY<br/>
                    <span class="caption">
                      พัฒนาทักษะ ก้าวทันโลกแห่งข้อมูล ก้าวหน้าในโลกดิจิทัล
                    </span>
                  </span>
                </v-col>
              </v-row>
            </v-col>
        </v-container>
      </div>
    </div>
    <v-app class="ct-mw">
      <v-container>
        <v-row>
          <!-- Mobile -->
          <v-col cols="12">
            <v-row justify="center">
              <router-link
                :to="`/payment/cart/${sku}`"
                class="button hidden-md-and-up ltsp">
                <v-btn color="error"><strong> BUY NOW </strong></v-btn>
              </router-link>
              <!-- <v-btn
                v-else
                class="button hidden-md-and-up ltsp"
                disabled
              >
                <strong> SOLD OUT </strong>
              </v-btn> -->
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="4" class="hidden-md-and-up">
            <v-container class="white-bg shadow">
            <br/>
              <v-row dense no-gutters
                v-for="salebox in salebox"
                :key="salebox.id">
                <v-col cols="2">
                  <v-row justify="center">
                    <v-img
                      v-if="salebox.imgicon"
                      :src="`${salebox.imgicon}`"
                      max-width="30px">
                    </v-img>
                    <v-img
                      v-else-if="!salebox.imgicon && salebox.icon"
                      max-width="30px"
                    >
                      <v-icon right>
                      {{salebox.icon}}
                      </v-icon>
                    </v-img>
                  </v-row>
                </v-col>
                <v-col v-if="'link' in salebox" class="text-content">
                  <a :href="`${salebox.link}`">
                    {{ salebox.title }}</a>
                </v-col>
                <v-col v-else-if="salebox.blank" cols="11"><br/></v-col>
                <v-col v-else class="text-content">
                  {{ salebox.title }}
                </v-col>
              </v-row>
            </v-container>
             <v-responsive :aspect-ratio="16/9" class="pt-6">
              <iframe
                :src="teaserLink"
                width="100%"
                height="100%"
                frameBorder="0"
                allow="fullscreen; encrypted-media; gyroscope; picture-in-picture;"
                allowFullScreen
              >
              </iframe>
            </v-responsive>

          </v-col>
          <!-- About Product  -->
          <v-col cols="12" md="8">
            <v-content class="white-bg mt-24px pt-0">
              <div class="pd-24px text-content">
                ชุดวิชาเกี่ยวกับวิทยาศาสตร์ข้อมูล ประกอบด้วย การเก็บรวบรวม
                จัดการ วิเคราะห์ และเปลี่ยนแปลงข้อมูลอันมหาศาล ไปสู่ความรู้อันมีคุณค่า
                ที่สามารถนำไปใช้ให้เกิดประโยชน์ได้อีกมากมาย
                ซึ่งสำคัญมากต่อการพัฒนา ธุรกิจที่มีความเป็นดิจิทัลในปัจจุบัน
                เพราะข้อมูลเชิงลึกที่ได้มาจะสามารถช่วยเราในการตัดสินใจหรือวางแผนอนาคตได้
                <br/><br/>

                <v-img
                  :src="require('../../assets/image/whatisdatasci.png')"
                ></v-img>
                <br/>

                <span class="title font-weight-black">รายวิชาที่เรียน</span><br/>

                <strong>COURSE 1: Competing in a Data-Driven World
                Understand and Know-How</strong><br/>
                เปลี่ยนโลกที่เต็มไปด้วยข้อมูลให้เป็นโลกที่ขับเคลื่อนด้วยข้อมูล
                เพื่อให้องค์กรเป็นผู้ชนะในโลกแห่งการแข่งขันนี้ รายวิชานี้จะให้มุมมอง
                แบบองค์รวม นําเสนอ Best Practices รวมถึงกรณีศึกษาต่าง ๆ เพื่อให้ผู้เรียน
                เข้าใจและรู้วิธีที่จะนำ Data ไปปฏิบัติงานจริง<br/>

                <strong>COURSE 2: Data Analytics and Big Data</strong><br/>
                เรียนรู้เกี่ยวกับศาสตร์แห่งการวิเคราะห์ข้อมูล เรียนรู้วิธีการวิเคราะห์
                เทคนิคในการนำข้อมูลมาใช้ ขั้นตอนวิธีการทำงาน และเครื่องมือที่ใช้
                มีกรณีศึกษา ทั้งข้อมูลด้านการศึกษา Social Network รวมไปถึง Geospatial Data<br/>

                <strong>COURSE 3: Practical Data Analytics Using RapidMiner</strong><br/>
                เรียนรู้และวิเคราะห์ข้อมูลด้วย Machine Learning โดยใช้โปรแกรม
                RapidMiner เพื่อให้เข้าใจถึงความสัมพันธ์ของข้อมูล พฤติกรรมต่าง ๆ
                ของลูกค้า และใช้ในการทำนายข้อมูล
                ซึ่งจะช่วยสนับสนุนการวางแผนและตัดสินใจทางธุรกิจได้ <br/>

                <strong>COURSE 4: Python for Data Science</strong><br/>
                เรียนรู้การใช้ภาษา Python ในระดับเริ่มต้น โดยเริ่มจากการเรียน
                Syntax ไปจนถึงการนำมาใช้ในการดึงข้อมูลจาก Social Network
                เตรียมข้อมูล เพื่อให้ง่ายต่อการวิเคราะห์ความสัมพันธ์
                และนำเสนอข้อมูลในรูปแบบต่าง ๆ ได้<br/>
                <br/>

                <span class="title font-weight-black">เนื้อหาหลักสูตร</span><br/>
                <v-row>
                  <v-col cols="1" class="px-1 text-end">
                    <v-icon>query_builder</v-icon>
                  </v-col>
                  <v-col  cols="11" sm="8" class="px-0">
                    เวลาเรียนรู้ รวมทำโปรเจกต์ และแบบฝึกหัด ประมาณ 30 ชั่วโมง
                  </v-col>
                  <v-col cols="1" class="px-1 text-end">
                    <v-icon>play_circle_outline</v-icon>
                  </v-col>
                  <v-col cols="11" sm="2" class="px-0">
                     96 คลิป
                  </v-col>
                </v-row>
                <v-expansion-panels accordion>
                  <v-expansion-panel
                    v-for="(item, i) in courseOutline"
                    :key="i"
                  >
                    <v-expansion-panel-header>
                      <span class="font-weight-black navy-text">
                        <v-row class="pa-0">
                          <v-col cols="12" sm="3"
                          class="py-0 pr-sm-0 text-sm-center header-content">
                            COURSE {{item.course}}:
                          </v-col>
                          <v-col cols="12" sm="9" class="py-0 pl-sm-0 header-content">
                            {{item.name}}
                          </v-col>
                        </v-row>
                      </span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-expansion-panels>
                        <v-expansion-panel
                          v-for="(part, index) in item.parts"
                          :key="index"
                        >
                          <v-expansion-panel-header>
                            <span
                              class="font-weight-medium navy-text"
                            >
                              <v-row class="py-0 my-0">
                                <v-col cols="12" sm="2" class="pa-0 header-content">
                                  Part {{part.part}}:
                                </v-col>
                                <v-col cols="12" sm="7" class="pa-0 px-1 header-content">
                                   {{part.name}}
                                </v-col>
                                <v-col cols="12" sm="3"
                                  class="pa-0 text-sm-end pr-3 header-content"
                                >
                                  {{part.chapters.length}} คลิป
                                </v-col>
                              </v-row>
                            </span>
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <v-list>
                              <v-list-item
                                v-for="(chap, indexChap) in part.chapters"
                                :key="indexChap"
                              >
                                <v-row class="pa-0 base-text">
                                  <v-col class="pa-0 px-1 text-content" cols="1">
                                    -
                                  </v-col>
                                  <v-col cols="11" class="pa-0 px-1 text-content">
                                    {{chap}}
                                  </v-col>
                                </v-row>
                              </v-list-item>
                            </v-list>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
                <br/>


                <span class="title font-weight-black">Data Science Pathway เหมาะกับใคร</span><br/>
                คอร์สนี้เหมาะสำหรับคนทำงานทั้งระดับ Executive, Manager และ Operation<br/>
                - ระดับ Executive สามารถให้วิสัยทัศน์ในภาพกว้าง
                เพื่อขับเคลื่อนองค์กรด้วยข้อมูลให้เท่าทันคู่แข่ง<br/>
                - ระดับ Manager สามารถใช้ประโยชน์จากข้อมูล
                เพื่อพัฒนาเทคโนโลยีและส่งเสริมวิสัยทัศน์ขององค์กร<br/>
                - ระดับ Operation สามารถใช้ทักษะจากการเรียนชุดวิชานี้
                เพื่อปรับปรุงงาน Day-to-Day ของแผนกอย่างชาญฉลาดมากยิ่งขึ้น<br/>
                <strong> การันตีด้วยจำนวนผู้เรียนที่มากกว่า 1,800 คนภายใน 4 เดือน</strong>
                <br/><br/>

                <span class="title font-weight-black">สิ่งที่คุณจะได้รับ</span><br/>
                เรียนรู้การนำข้อมูลมหาศาลมารวบรวม วิเคราะห์ จัดการอย่างเป็นระบบ
                เพื่อนำข้อมูลมาใช้วางแผนการทำงานในอนาคตได้ ตัวอย่างเช่น เจ้าของธุรกิจสตาร์ทอัพ
                นักธุรกิจองค์กร นักการตลาด นักเศรษฐศาสตร์ เกษตรกร ดีไซน์เนอร์ เป็นต้น<br/>

                <br/>
                <v-img
                  class="d-none d-sm-flex"
                  :src="require('../../assets/image/learningperiod-pathway-desktop.jpg')"
                ></v-img>

                <v-img
                  class="d-flex d-sm-none"
                  :src="require('../../assets/image/learningperiod-pathway-mobile.png')"
                ></v-img>

                <br/><br/>

                <span class="title font-weight-black">อาจารย์ผู้สอน</span><br/>
                <v-row>
                  <v-col cols="12" sm="6" lg="4"
                    v-for="(instructor, index) in instructors"
                    v-bind:key="index"
                  >
                    <v-card flat>
                      <v-container>
                        <v-row justify="center">
                          <v-img
                            aspect-ratio="1"
                            :src="instructor.img"
                            max-height="150px"
                            max-width="150px"
                          ></v-img>
                        </v-row>
                        <v-row justify="center" class="text-content font-weight-bold navy-text">
                          {{instructor.tth}} {{instructor.fth}} {{instructor.lth}}
                        </v-row>
                        <v-row justify="center" class="text-center prof font-weight-light">
                          {{instructor.ten}} {{instructor.fen}} {{instructor.len}}<br/>
                          {{instructor.dep}}<br/>
                          {{instructor.fac}}<br/>
                          จุฬาลงกรณ์มหาวิทยาลัย
                        </v-row>
                      </v-container>
                    </v-card>
                  </v-col>
                </v-row>
                <br/>


                <span class="title font-weight-black">FAQ</span><br/><br/>
                <v-expansion-panels accordion>
                  <v-expansion-panel
                    v-for="(item,i) in faqs"
                    :key="i"
                  >
                    <v-expansion-panel-header>
                      <span class="header-content font-weight-bold navy-text">{{item.q}}</span>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <span class="text-content">{{item.a}}</span>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
                <br/><br/>

                สอบถามข้อมูลเพิ่มเติม<br/>
                LINE Official Account: <a href="https://line.me/R/ti/p/@achieveplus" target="_blank">@achieveplus</a><br/>
                Facebook: <a href="https://www.facebook.com/Achieve.plus.th" target="_blank">Achieve Plus</a><br/><br/>
              </div>

            </v-content>
          </v-col>
          <!-- Desktop -->
          <v-col cols="12" md="4" class="hidden-sm-and-down">
            <v-responsive :aspect-ratio="16/9" class="mb-4">
              <iframe
                :src="teaserLink"
                width="100%"
                height="100%"
                frameBorder="0"
                allow="fullscreen; encrypted-media; gyroscope; picture-in-picture;"
                allowFullScreen
              >
              </iframe>
            </v-responsive>
            <v-content class="white-bg shadow mt-24px">
              <v-col cols="12">
                <v-row justify="center">
                  <router-link
                    :to="`/payment/cart/${sku}`"
                    class="button ltsp">
                    <v-btn color="error"><strong> BUY NOW </strong></v-btn>
                  </router-link>
                  <!-- <v-btn
                    v-else
                    class="button ltsp"
                    disabled
                  >
                    <strong> SOLD OUT </strong>
                  </v-btn> -->
                </v-row>
              </v-col>
              <v-container>
                <br/>
                <v-row dense no-gutters
                  v-for="salebox in salebox"
                  :key="salebox.id">
                  <v-col cols="2">
                    <v-row justify="center">
                      <v-img
                        v-if="salebox.imgicon"
                        :src="`${salebox.imgicon}`"
                        max-width="30px">
                      </v-img>
                      <v-img
                        v-else-if="!salebox.imgicon && salebox.icon"
                        max-width="30px"
                      >
                        <v-icon right>
                        {{salebox.icon}}
                        </v-icon>
                      </v-img>
                    </v-row>
                  </v-col>
                  <v-col v-if="'link' in salebox" class="text-content">
                    <a :href="`${salebox.link}`">
                      {{ salebox.title }}</a>
                  </v-col>
                  <v-col v-else-if="salebox.blank" cols="11"><br/></v-col>
                  <v-col v-else class="text-content">
                    {{ salebox.title }}
                  </v-col>
                </v-row>
              </v-container>

            </v-content>
          </v-col>
        </v-row>
      </v-container>
    </v-app>
  </body>
</template>

<script>
export default {
  name: 'pathwayDetail',
  props: ['pathwayId'],
  data() {
    return {
      card_text: '',
      sku: 'pw0010009',
      title: 'Data Science Pathway',
      teaserLink: 'https://www.youtube.com/embed/WUqP_bO1Fiw?rel=0&modestbranding=1',
      salebox: [
        {
          title: 'ชุดวิชา (pathway)', icon: 'fas fa-laptop-code', imgicon: require('@/assets/image/icon-06.svg'), id: 1,
        },
        {
          title: 'เรียนได้ทันทีเมื่อชำระเงิน', icon: 'fas fa-map-marker-alt', imgicon: require('@/assets/image/icon-09.svg'), id: 2,
        },
        {
          title: '3 เดือน', icon: 'far fa-clock', imgicon: require('@/assets/image/icon-10.svg'), id: 3,
        },
        {
          title: 'เรียนออนไลน์', imgicon: require('@/assets/image/icon-14.svg'), id: 4,
        },
        {
          title: 'ระดับพื้นฐาน (Fundamental level)', icon: 'fas fa-tasks', imgicon: require('@/assets/image/icon-12.svg'), id: 5,
        },
        {
          blank: true,
        },
        {
          title: '30 ชั่วโมง วิดีโอสั้น เข้าใจง่าย', icon: 'fas fa-tasks', imgicon: require('@/assets/image/icon-04.svg'), id: 6,
        },
        {
          title: '4 รายวิชา 34 บทเรียน 96 คลิป', id: 7,
        },
        {
          title: '5,500 บาท', icon: 'local_offer', id: 8,
        },
        // {
        //   title: 'Achieve+ Member รับส่วนลด 25%', link: '/member', icon: 'fas fa-star',
        //   imgicon: require('@/assets/image/icon-13.svg'), id: 9,
        // },
      ],
      courseOutline: [
        {
          course: 1,
          name: 'Competing in a Data-Driven World Understand and Know-How',
          des: 'เปลี่ยนโลกที่เต็มไปด้วยข้อมูลให้เป็นโลกที่ขับเคลื่อนด้วยข้อมูล เพื่อให้องค์กรเป็นผู้ชนะในโลกแห่งการแข่งขันนี้ รายวิชานี้จะให้มุมมอง แบบองค์รวม นําเสนอ Best Practices รวมถึงกรณีศึกษาต่าง ๆ เพื่อให้ผู้เรียน เข้าใจและรู้วิธีที่จะนำ Data ไปปฏิบัติงานจริง',
          parts: [
            {
              part: 0,
              name: 'Course Introduction',
              chapters: [
                'Course Introduction',
              ],
            },
            {
              part: 1,
              name: 'Data-Driven Organization',
              chapters: [
                'We Are In a World Full of Data',
                'Digital Data',
                'The Age of Data Analytics',
                'Data-Driven Organization',
                'From HBR, MIT Sloan Management Review, and Gartner',
              ],
            },
            {
              part: 2,
              name: 'From Data to Insights to Actions',
              chapters: [
                'Turn Data into Actionable Insights',
                'Customer Behaviour Analytics to Acquire New Customers, Better Engage or Retain Existing Ones, or Increase Loyalty',
                'Data Science',
                'Data Science Project Life Cycle 1 ',
                'Data Science Project Life Cycle 2',
              ],
            },
            {
              part: 3,
              name: 'Transforming into a Data-Driven Organization',
              chapters: [
                'Why Organizations are Transforming into Data-Driven Organization ?',
                'How the Data-Driven Organization Takes Shape',
                'Mastering the Basics of Data Management',
                'Venturing to the New World of Data Partnerships',
                'Treating Data as Strategic Asset',
                'Commercializing Data and Business Model Innovation',
                'Data-Driven Organization Maturity',
              ],
            },
            {
              part: 4,
              name: 'Advanced Data Analytics and Use Cases',
              chapters: [
                'What is Advanced Data Analytics?',
                'Advanced Analytics Maturity',
                'Descriptive Analytics',
                'Diagnostic Analytics​',
                'Predictive Analytics',
                'Prescriptive Analytics',
                'Cognitive Analytics',
              ],
            },
            {
              part: 5,
              name: 'Big Data Analytics: Do and Don’t',
              chapters: [
                'Data-Driven Organization: Do',
                'Data-Driven Organization: Don’t',
              ],
            },
          ],
        },
        {
          course: 2,
          name: 'Data Analytics & Big Data',
          des: 'เรียนรู้เกี่ยวกับศาสตร์แห่งการวิเคราะห์ข้อมูล เรียนรู้วิธีการวิเคราะห์ เทคนิคในการนำข้อมูลมาใช้ ขั้นตอนวิธีการทำงาน และเครื่องมือที่ใช้ มีกรณีศึกษา ทั้งข้อมูลด้านการศึกษา Social Network รวมไปถึง Geospatial Data',
          parts: [
            {
              part: 0,
              name: 'Overview to the Course',
              chapters: [
                'Overview to the Course',
              ],
            },
            {
              part: 1,
              name: 'Big Data Intro to Practice',
              chapters: [
                'Data Science in Action',
                'Analytics for Education',
                'Social Network Analytics',
                'Where is Big Question',
                'Implementation with RapidMiner',
                'Import and Explore Data',
                'Import and Explore Data',
                'Data Preparation',
                'Prediction Model',
              ],
            },
            {
              part: 2,
              name: 'Big Data Intro to Practice',
              chapters: [
                'The Age of Big Data',
                'Map Reduce',
                'Big Data Tools',
                'Big Data with RapidMiner 1',
                'Big Data with RapidMiner 2',
                'Internet of Things',
                'Conclusion and Next Action',
              ],
            },
          ],
        },
        {
          course: 3,
          name: 'Practical Data Analytics Using RapidMiner',
          des: 'เรียนรู้และวิเคราะห์ข้อมูลด้วย Machine Learning โดยใช้โปรแกรม RapidMiner เพื่อให้เข้าใจถึงความสัมพันธ์ของข้อมูล พฤติกรรมต่าง ๆ ของลูกค้า และใช้ในการทำนายข้อมูล ซึ่งจะช่วยสนับสนุนการวางแผนและตัดสินใจทางธุรกิจได้ ',
          parts: [
            {
              part: 1,
              name: 'Overview to the Course',
              chapters: [
                'Overview to the Course',
              ],
            },
            {
              part: 2,
              name: 'RapidMiner Lab',
              chapters: [
                'RapidMiner Lab',
              ],
            },
            {
              part: 3,
              name: 'Supervised Learning',
              chapters: [
                'Supervised Learning 1',
                'Supervised Learning 2',
                'An Overview on Classification Lab',
                'Classification Lab 1',
                'Classification Lab 2',
                'Classification Lab 3',
                'Classification Lab 4',
                'An Overview on Regression Lab',
                'Regression Lab 1',
                'Regression Lab 2',
                'Regression Lab 3',
              ],
            },
            {
              part: 4,
              name: 'Unsupervised Learning',
              chapters: [
                'Unsupervised Learning',
                'An Overview on Clustering Lab',
                'Clustering Lab 1',
                'Clustering Lab 2',
                'Clustering Lab 3',
                'An Overview on Association Rule Mining Lab',
                'Association Rule Mining Lab 1',
                'Association Rule Mining Lab 2',
                'Association Rule Mining Lab 3',
              ],
            },
            {
              part: 5,
              name: 'Summary',
              chapters: [
                'Summary 1',
                'Summary 2',
              ],
            },
          ],
        },
        {
          course: 4,
          name: 'Python for Data Science',
          des: 'เรียนรู้การใช้ภาษา Python ในระดับเริ่มต้น โดยเริ่มจากการเรียน Syntax ไปจนถึงการนำมาใช้ในการดึงข้อมูลจาก Social Network เตรียมข้อมูล เพื่อให้ง่ายต่อการวิเคราะห์ความสัมพันธ์ และนำเสนอข้อมูลในรูปแบบต่าง ๆ ได้',
          parts: [
            {
              part: 0,
              name: 'Installation and Introduction',
              chapters: [
                'Installation (Library, install) part 1',
                'Installation (Library, install) part 2',
                'Installation (Library, install) part 3',
              ],
            },
            {
              part: 1,
              name: 'Introduction to Python',
              chapters: [
                'Introduction: Introduction to Python',
                'Python Strings Manipulation',
                'Python Lists part 1',
                'Python Lists part 2',
                'Python Dictionary, Set & Tuples part 1',
                'Python Dictionary, Set & Tuples part 2',
                'Ending: Introduction to Python',
              ],
            },
            {
              part: 2,
              name: 'Numpy and Matplotlib Library',
              chapters: [
                'Introduction: Numpy and Matplotlib Library',
                'Introduction to Numpy and Numpy Array',
                'Matrix Operations',
                'Graph Plot (matplotlib) part 1',
                'Graph Plot (matplotlib) part 2',
                'Ending: Numpy and Matplotlib Library',
              ],
            },
            {
              part: 3,
              name: 'Getting to Know Pandas',
              chapters: [
                'Introduction: Getting to Know Pandas',
                'Introduction to Pandas and Series',
                'Data Frame & File Reading',
                'Selections & Plot',
                'Ending: Getting to Know Pandas',
              ],
            },
            {
              part: 4,
              name: 'Social Media Retrievals',
              chapters: [
                'Introduction: Social Media Retrievals',
                'Facebook Graph API',
                'Json & Facebook result',
                'Facebook result analysis',
                'Ending: Social Media Retrievals',
              ],
            },
          ],
        },
      ],
      instructors: [
        {
          img: require('../../assets/image/ChulaMooc_Achieve_DataScience_Pathway_Instructor-01.png'),
          tth: 'ผศ.ดร.',
          fth: 'ณัฐวุฒิ',
          lth: 'หนูไพโรจน์',
          ten: 'Asst.Prof.Dr.',
          fen: 'Natawut',
          len: 'Nupairoj',
          dep: 'ภาควิชาวิศวกรรมคอมพิวเตอร์',
          fac: 'คณะวิศวกรรมศาสตร์',
        },
        {
          img: require('../../assets/image/ChulaMooc_Achieve_DataScience_Pathway_Instructor-04.png'),
          tth: 'อ.ดร.',
          fth: 'วัชรา',
          lth: 'จันทาทับ',
          ten: 'Dr.',
          fen: 'Wachara',
          len: 'Chantatub',
          dep: 'ภาควิชาสถิติ',
          fac: 'คณะพาณิชยศาสตร์และการบัญชี',
        },
        {
          img: require('../../assets/image/ChulaMooc_Achieve_DataScience_Pathway_Instructor-07.png'),
          tth: 'ศ.ดร.',
          fth: 'ประภาส',
          lth: 'จงสถิตย์วัฒนา',
          ten: 'Prof.Dr.',
          fen: 'Prabhas',
          len: 'Chongstitvatana',
          dep: 'ภาควิชาวิศวกรรมคอมพิวเตอร์',
          fac: 'คณะวิศวกรรมศาสตร์',
        },
        {
          img: require('../../assets/image/ChulaMooc_Achieve_DataScience_Pathway_Instructor-08.png'),
          tth: 'รศ.ดร.',
          fth: 'อติวงศ์',
          lth: 'สุชาโต',
          ten: 'Assoc.Prof.Dr.',
          fen: 'Atiwong',
          len: 'Suchato',
          dep: 'ภาควิชาวิศวกรรมคอมพิวเตอร์',
          fac: 'คณะวิศวกรรมศาสตร์',
        },
        {
          img: require('../../assets/image/ChulaMooc_Achieve_DataScience_Pathway_Instructor-06.png'),
          tth: 'รศ.ดร.',
          fth: 'เกริก',
          lth: 'ภิรมย์โสภา',
          ten: 'Assoc.Prof.Dr.',
          fen: 'Krerk',
          len: 'Piromsopa',
          dep: 'ภาควิชาวิศวกรรมคอมพิวเตอร์',
          fac: 'คณะวิศวกรรมศาสตร์',
        },
        {
          img: require('../../assets/image/ChulaMooc_Achieve_DataScience_Pathway_Instructor-05.png'),
          tth: 'ผศ.ดร.',
          fth: 'วีระ',
          lth: 'เหมืองสิน',
          ten: 'Asst.Prof.Dr.',
          fen: 'Veera',
          len: 'Muangsin',
          dep: 'ภาควิชาวิศวกรรมคอมพิวเตอร์',
          fac: 'คณะวิศวกรรมศาสตร์',
        },
        {
          img: require('../../assets/image/ChulaMooc_Achieve_DataScience_Pathway_Instructor-03.png'),
          tth: 'ผศ.ดร.',
          fth: 'สุกรี',
          lth: 'สินธุภิญโญ',
          ten: 'Asst.Prof.Dr.',
          fen: 'Sukree',
          len: 'Sinthupinyo',
          dep: 'ภาควิชาวิศวกรรมคอมพิวเตอร์',
          fac: 'คณะวิศวกรรมศาสตร์',
        },
        {
          img: require('../../assets/image/ChulaMooc_Achieve_DataScience_Pathway_Instructor-02.png'),
          tth: 'ผศ.ดร.',
          fth: 'พีรพล',
          lth: 'เวทีกูล',
          ten: 'Asst.Prof.Dr.',
          fen: 'Peerapon',
          len: 'Vateekul',
          dep: 'ภาควิชาวิศวกรรมคอมพิวเตอร์',
          fac: 'คณะวิศวกรรมศาสตร์',
        },
      ],
      faqs: [
        {
          q: 'Data Science Pathway คืออะไร สำคัญอย่างไร',
          a: 'วิทยาศาสตร์ข้อมูล คือ ศาสตร์แห่งการเก็บรวบรวม จัดการ วิเคราะห์ และเปลี่ยนแปลงข้อมูลอันมหาศาลไปสู่ความรู้ที่สามารถนำไปใช้ให้เกิดประโยชน์ได้อีกมากมาย ซึ่งสำคัญมากต่อการพัฒนาธุรกิจที่มีความเป็นดิจิทัลอย่างมากในปัจจุบัน เพราะข้อมูลเชิงลึกที่เราได้มาจะมาช่วยในการตัดสินใจหรือวางแผนธุรกิจในอนาคตได้',
        },
        {
          q: 'Data Science Pathway เหมาะกับใคร',
          a: 'วัยทำงานทั้งระดับ Executive, Manager และ Operation - ระดับ Executive สามารถให้วิสัยทัศน์ในภาพกว้าง เพื่อขับเคลื่อนองค์กรด้วยข้อมูล ให้เท่าทันคู่แข่ง - ระดับ Manager สามารถใช้ประโยชน์จากข้อมูล เพื่อพัฒนาเทคโนโลยีและส่งเสริมวิสัยทัศน์ขององค์กร - ระดับ Operation สามารถใช้ทักษะจากการเรียนชุดวิชานี้ เพื่อปรับปรุงงาน Day-to-Day ของแผนกอย่างชาญฉลาดมากยิ่งขึ้นและอาชีพต่าง ๆ ตามที่ยกตัวอย่างไปในหน้าแรก',
        },
        {
          q: 'เนื้อหาวิชานี้สามารถนำไปใช้ในสถานการณ์ใดได้บ้าง',
          a: 'ชุดวิชานี้สามารถใช้พื่อดูความสัมพันธ์ที่เกิดขึ้นของข้อมูล การที่เรารู้ในเรื่องนี้จะช่วยให้เราสามารถวิเคราะห์หลาย ๆ สิ่งรอบตัวเราได้มากขึ้น เช่น การจัดกลุ่มของผู้คนจำนวนมากที่มีอยู่ในระบบ การทำนายสภาพอากาศจากสภาวะแวดล้อม และ การวิเคราะห์ว่าถ้าผู้ใช้ซื้อสินค้าชนิดหนึ่งจะสนใจสินค้าอีกชนิดหนึ่งด้วย เป็นต้น',
        },
        {
          q: 'นอกจากเนื้อหาการเรียนแล้ว ผู้เรียนจะได้รับอะไรอีก',
          a: 'นอกจากเนื้อหาการเรียนในรูปแบบคลิปวิดีโอสั้น กระชับ ตรงประเด็น ผู้เรียนหลักสูตรของ Achieve ยังได้รับเอกสารประกอบการเรียน กรณีศึกษา บทสรุปเนื้อหา คู่มือการดาวน์โหลดโปรแกรม RapidMiner9.0 และ คู่มือการตั้งค่า RapidMiner9.0 ที่สำคัญเพื่อประกอบการฝึกปฏิบัติในหลักสูตร Data Science Pathway อีกทั้งผู้เรียนจะได้ร่วมเป็นส่วนหนึ่งของสังคมแห่งการเรียนรู้ ผ่านกลุ่มปิด Facebook Group ทำให้การเรียนรู้ไม่น่าเบื่อ เรียนไปพร้อมกับเพื่อน และ Pathway Assistant ที่จะคอยช่วยเหลือตลอดระยะเวลา 3 เดือน',
        },
        {
          q: 'ไม่มีความรู้พื้นฐานด้าน Data Science สามารถเรียนได้ไหม',
          a: 'เราได้วางเนื้อหาเรียงลำดับจากระดับพื้นฐาน ที่เหมาะสำหรับผู้ที่พึ่งเริ่มเรียนเกี่ยวกับ Data Science เป็นครั้งแรก ไปจนถึงเนื้อหาที่ซับซ้อนขึ้นอย่างเป็นลำดับ ทำให้ผู้ที่ไม่มีความรู้พื้นฐานเลยสามารถทำความเข้าใจในเนื้อหาวิชาได้ ไม่ยากเกินไปที่จะลอง อย่างไรก็ดี หากท่านมีพื้นฐานด้านสถิติ หรือ การเขียนโปรแกรมด้วยภาษาคอมพิวเตอร์มาบ้าง จะยิ่งทำให้การเรียนรู้ของท่านเป็นไปด้วยความรวดเร็ว คล่องตัว และนำไปสู่การใช้งานได้อย่างชำนาญเร็วขึ้น',
        },
        {
          q: 'การเรียน Pathway ต่างจากการเรียนคอร์สทั่วไปอย่างไร',
          a: 'เป็นการเรียนรู้จากทฤษฎีพื้นฐานไปสู่การนำไปประยุกต์ใช้ โดยเริ่มต้นจากการรู้จักและเรียนรู้เครื่องมือในการเขียนโปรแกรมขั้นพื้นฐานที่สามารถนำไปใช้ในการจัดการกับข้อมูลจำนวนไม่มากและไม่ซับซ้อน ไปสู่การเรียนรู้เครื่องมือที่สามารถจัดการกับข้อมูลจำนวนมากและมีความซับซ้อนขึ้นได้ จนในที่สุดเมื่อผู้เรียนได้รู้จักเครื่องมือในการเขียนโปรแกรมใน Pathway นี้แล้วจะสามารถนำทักษะดังกล่าวไปประยุกต์ใช้กับข้อมูลในชีวิตประจำวันได้',
        },
        {
          q: 'Certification พิเศษอย่างไร',
          a: 'เป็น Institute Certificate ที่ได้รับการรับรองจากจุฬาลงกรณ์มหาวิทยาลัยซึ่งเป็นสถาบันการศึกษาชั้นนำของประเทศ',
        },
        {
          q: 'หากมีคำถามหรือข้อสงสัยระหว่างเรียน สามารถติดต่อได้ทางช่องทางไหนได้บ้าง',
          a: 'ผ่าน Facebook Closed Group สำหรับผู้เรียนโดยเฉพาะโดยมี PA (Pathway Assistant) เป็นผู้ดูแลให้ความช่วยเหลือ รวมถึงจัดกิจกรรมเสริมสร้างการเรียนรู้ ไม่ว่าจะเป็นการตอบข้อสงสัยเกี่ยวกับบทเรียน การให้เอกสารหรือกรณีศึกษาเพิ่มเติมประกอบวิชา เป็นต้น',
        },
      ],
    };
  },
};
</script>
<style scoped>
.ct-mw{
  max-width: 1100px;
  margin: auto;
}
.banner-h {
   height: 200px;
}
.banner-bg{
  background-image: url('../../assets/image/data-science-pathway.jpg');
  background-position: center;
  background-size: cover;
  height: 250px;

  /* margin-left: -600px;
  margin-right: -600px; */
}
.line-banner {
  border: none;
  border-left: 0.4rem solid #fff;
  height: 6rem;
  width: 0.5rem;
  max-inline-size: min-content;
}
.banner-content{
  max-width: 1100px;
  margin: auto;
}
.content{
  height: 1500px;
  margin: auto;
  max-width: 1200px;
}
.heading{
  font-size: 1.3125rem;
  font-weight: 600;
  letter-spacing: 2px;
}
.pd-24px{
  margin-left: 31px;
  margin-right: 32px;
}
.pd-12px{
  padding-left: 12px;
}
.pdr-16px{
  padding-left: 24px;
  padding-right: 16px;
}
.white-bg{
  box-shadow: #001544;
  border: #001544;
}
.shadow{
  box-shadow: 2px 2px 10px #dfdfdf;
  border-color: #001544;
}
.mt-24px{
  margin-top: 24px;
}
.mlr-24px{
  margin-left: 24px;
  margin-right: 24px;
}
.v-icon{
  font-size: 16px;
}
.mgt-12px{
  margin-top: 12px;
}
.v-btn__content{
  font-weight: 700;
}
.ltsp{
  font-weight: 700;
  letter-spacing: 2px;
}
.mg{
  margin-bottom: 16px;
}
.prof {
  line-height: 20px;
  font-size: 0.75rem;
}
@media screen and (max-width: 770px){
  .heading{
    font-size: 1.0rem;
    font-weight: 600;
    letter-spacing: 2px;
    line-height: 1.4rem;
  }
}
</style>
